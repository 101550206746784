import { render, staticRenderFns } from "./approalCard.vue?vue&type=template&id=53f04a64&scoped=true&"
import script from "./approalCard.vue?vue&type=script&lang=ts&"
export * from "./approalCard.vue?vue&type=script&lang=ts&"
import style0 from "./approalCard.vue?vue&type=style&index=0&id=53f04a64&scoped=true&lang=scss&"
import style1 from "./approalCard.vue?vue&type=style&index=1&media=print&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f04a64",
  null
  
)

export default component.exports